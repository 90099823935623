import { defineStore } from 'pinia'

interface AppRootState {

  version: string

  initialised: boolean
  sessionState: SessionState

  fetchingData: boolean

  audioPlayerState: AudioPlayerState

  taisHelpActive: boolean
}

export const useAppStore = defineStore({
  id: 'appstore',

  state: (): AppRootState => ({
    version: 'v4.45',
    initialised: false,

    sessionState: 'dashboard-ready',

    fetchingData: false,

    // States
    audioPlayerState: 'ready',
    taisHelpActive: false,
  }),

  actions: {
    updateInitialised(initValue: boolean) {
      this.initialised = initValue
    },

    setSessionState(state: SessionState) {
      this.sessionState = state
    },

  },

  getters: {
    getInitialised: state => state.initialised,
    getSessionState: state => state.sessionState,

    isSessionConsideration: state => ['session-complete-consideration', 'session-complete-reactivate', 'session-complete-create'].includes(state.sessionState),

    isPreSessionStart: state => ['session-ready-to-start', 'session-entering-tais'].includes(state.sessionState),
  },
})
