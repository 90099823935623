// plugins/api.ts
import { ApiClient } from '@/api/apiclient'
import type { ApiClientInstance } from '@/api/apiclient'

import { AuthService } from '@/api/services/auth'
import { SessionService } from '@/api/services/session'
import { UserService } from '@/api/services/user'

// Set the environment variable to 0 (to disable certificate validation) before making any requests
// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export default defineNuxtPlugin((nuxtApp) => {
  // Create interceptors here
  const api: ApiClientInstance = new ApiClient({})

  // Api Services
  const authService = new AuthService(api)
  const sessionService = new SessionService(api)
  const userService = new UserService(api)

  return {
    provide: {
      api: {
        ...api,
        auth: authService,
        session: sessionService,
        user: userService,
      },
    },
  }
})
