// =============================================================================
// Some Variable testing functions to ensure data is returned correctlly
// =============================================================================

export function testChainKIO(testObj: object, testKey: string) {
  if (isObject(testObj) && testKey)
    return Object.prototype.hasOwnProperty.call(testObj, testKey)

  else
    return false
}
export function testChainKIOE(testObj: object, testKey: string) {
  if (isObject(testObj) && testKey) {
    return (
      Object.prototype.hasOwnProperty.call(testObj, testKey) && testObj[testKey]
    )
  }
  else {
    return false
  }
}
export function testChainKIOL(testObj: object, testKey: string) {
  if (isObject(testObj) && testKey) {
    return (
      Object.prototype.hasOwnProperty.call(testObj, testKey)
      && testObj[testKey].length > 0
    )
  }
  else {
    return false
  }
}
export function testKeyChain(obj: object, keys: Array<unknown>, type = 'KIO') {
  let success = true
  let testObj = {}
  let testKey = ''

  for (let i = 0; i < keys.length; i++) {
    if (i === 0) {
      testObj = obj
      testKey = keys[i]
    }
    else {
      testKey = keys[i]
      testObj = testObj[keys[i - 1]]
    }

    switch (type) {
      case 'KIO':
        if (!testChainKIO(testObj, testKey)) {
          success = false
          break
        }
        break
      case 'KIOE':
        if (!testChainKIOE(testObj, testKey, 'KIOE')) {
          success = false
          break
        }
        break
      case 'KIOL':
        if (!testChainKIOL(testObj, testKey, 'KIOL')) {
          success = false
          break
        }
        break

      default:
        break
    }
  }
  return success
}

export function isObject(variable) {
  return typeof variable === 'object' && variable !== null
}

export function objectEmpty(obj: object) {
  return (
    Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype
  )
}

export function KIO<T>(obj: T, key: string | string[], mode = 'and') {
  // Obj => object in question
  // key => string or Array
  // mode => 'and' (default) || 'chain'
  // -> If mode is set to 'and' it implies that an array has been passed, it will check if the object contains all the keys present in the array
  // -> If mode is set to 'chain' it implies that an array has been passed, it will check if the object contains, the first key, then the second, then the third as children in a chained sequence.
  //    Example: ['auth', 'data', 'id'] -> KIO($user, 'auth') && KIO($user.auth, 'data') && KIO($user.auth, 'id')

  if (!isObject(obj))
    return false

  if (Array.isArray(key) && key.length > 0) {
    const keys = key
    if (mode === 'and') {
      return keys.every((key) => {
        return Object.prototype.hasOwnProperty.call(obj, key)
      })
    }
    else if (mode === 'chain') {
      return testKeyChain(obj, keys)
    }
    else {
      throw new Error('bad "Mode" set for "KIO"')
    }
  }
  else if (typeof key === 'string') {
    try {
      if (Object.prototype.hasOwnProperty.call(obj, key))
        return true

      else
        return false
    }
    catch (e) {
      throw new Error('Problem with data within "keyInObject" utility function')
    }
  }
  else {
    return false
  }
}

export function KIOL<T>(obj: T, key: string | string[], mode = 'and') {
  // Obj => object in question
  // key => string or Array
  // mode => 'and' (default) || 'chain'
  // -> If mode is set to 'and' it implies that an array has been passed, it will check if the object contains all the keys present in the array
  // -> If mode is set to 'chain' it implies that an array has been passed, it will check if the object contains, the first key, then the second, then the third as children in a chained sequence.
  //    Example: ['auth', 'data', 'id'] -> KIO($user, 'auth') && KIO($user.auth, 'data') && KIO($user.auth, 'id')
  if (!isObject(obj))
    return false

  if (Array.isArray(key) && key.length > 0) {
    const keys = key
    // return keys.every((key) => {
    //   return (
    //     Object.prototype.hasOwnProperty.call(obj, key) && obj[key].length > 0
    //   )
    // })
    if (mode === 'and') {
      return keys.every((key) => {
        return (
          Object.prototype.hasOwnProperty.call(obj, key) && obj[key].length > 0
        )
      })
    }
    else if (mode === 'chain') {
      return testKeyChain(obj, keys, 'KIOL')
    }
    else {
      throw new Error('bad "Mode" set for "KIOL"')
    }
  }
  else if (typeof key === 'string') {
    try {
      if (
        obj
        && isObject(obj)
        && Object.prototype.hasOwnProperty.call(obj, key)
        && obj[key].length > 0
      )
        return true

      else
        return false
    }
    catch (e) {
      throw new Error('Problem with data within "keyInObject" utility function')
    }
  }
  else {
    return false
  }
}

export function KIOE<T>(obj: T, key: string | string[], mode = 'and') {
  // Obj => object in question
  // key => string or Array
  // mode => 'and' (default) || 'chain'
  // -> If mode is set to 'and' it implies that an array has been passed, it will check if the object contains all the keys present in the array
  // -> If mode is set to 'chain' it implies that an array has been passed, it will check if the object contains, the first key, then the second, then the third as children in a chained sequence.
  //    Example: ['auth', 'data', 'id'] -> KIO($user, 'auth') && KIO($user.auth, 'data') && KIO($user.auth, 'id')

  if (!obj || !isObject(obj))
    return false

  if (Array.isArray(key) && key.length > 0) {
    const keys = key
    if (mode === 'and') {
      return keys.every((key) => {
        return Object.prototype.hasOwnProperty.call(obj, key) && obj[key]
      })
    }
    else if (mode === 'chain') {
      let success = true
      let testObj = {}
      let testKey = ''

      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          testObj = obj
          testKey = keys[i]
        }
        else {
          testKey = keys[i]
          testObj = testObj[keys[i - 1]]
        }

        if (
          !Object.prototype.hasOwnProperty.call(testObj, testKey)
          && obj[key]
        ) {
          success = false
          break
        }
      }
      return success
    }
    else {
      throw new Error('bad "Mode" set for "KIOE"')
    }
  }
  else if (typeof key === 'string') {
    try {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key])
        return true

      else
        return false
    }
    catch (e) {
      throw new Error('Problem with data within "keyInObject" utility function')
    }
  }
  else {
    return false
  }
}

export function keyInObject(obj: object, key: string) {
  try {
    return !!Object.prototype.hasOwnProperty.call(obj, key)
  }
  catch (e) {
    throw new Error('Problem with data within "keyInObject" utility function')
  }
}

export function falseWithZero(value: unknown) {
  return value || value === 0
}

// Find or false
export function fof(value: unknown) {
  if (value === 0)
    return 0

  else
    return value || false
}

// =============================================================================
// Strings
// =============================================================================

export function capFirst(str: string) {
  if (str && str.length > 0)
    return str.charAt(0).toUpperCase() + str.slice(1)

  else
    return str
}

export function toUpper(str: string) {
  if (str && str.length > 0)
    return str.toUpperCase()

  else
    return str
}

export function truncate(str: string, n: number, useWordBoundary = true) {
  if (str.length <= n)
    return str

  const subString = str.substr(0, n - 1) // the original check
  return (
    `${useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString}&hellip;`
  )
}

// =============================================================================
// Window Height and Widths
// =============================================================================

export function vh(value: number) {
  const h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  )
  return (value * h) / 100
}

export function vw(value: number) {
  const w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  )
  return (value * w) / 100
}

export function vmin(value: number) {
  return Math.min(vh(value), vw(value))
}

export function vmax(value: number) {
  return Math.max(vh(value), vw(value))
}

// Get the destination index for panel pagination
export function getPaginateIndex(collection: Array<any> | object, activeIndex: number, intent: string) {
  // Base obj to reference
  // Active index
  // Intent value
  let count = 0

  let min = 0

  let max = 0

  if (typeof collection === 'object' && collection !== null) {
    count = Object.keys(collection).length
    min = 0
    max = count - 1
  }
  else if (Array.isArray(collection) && collection.length > 0) {
    count = collection.length
  }
  else {
    return false
  }

  min = 0
  max = count ? count - 1 : 0

  // if (!(activeIndex || activeIndex === 0) || !intent) {
  //   return false
  // }

  if (typeof intent === 'number') {
    if (intent >= min && intent <= max) {
      // commit('SET_ACTIVE_NODE', intent)
      return intent
    }
  }
  if (typeof intent === 'string') {
    let toNode
    switch (intent) {
      case 'prev':
        toNode = activeIndex - 1
        if (toNode >= min) {
          // commit('SET_ACTIVE_NODE', toNode)
          return toNode
        }
        break
      case 'next':
        toNode = activeIndex + 1
        if (toNode <= max) {
          // commit('SET_ACTIVE_NODE', toNode)
          return toNode
        }
        break
    }
  }
}

export function shuffle<T>(a: Array<T>): Array<T> {
  let j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

export function AE(arr: unknown) {
  return Array.isArray(arr)
}
export function AEL(arr: unknown) {
  return Array.isArray(arr) && arr.length > 0
}

export function mergeArrays<T>(arr1: Array<T>, arr2: Array<T>) {
  if (AE(arr1) && AE(arr2))
    return Array.from(new Set(arr1.concat(arr2).sort((a, b) => a - b)))

  else
    return []
}

export function rand(min: number, max: number) {
  if (!isNaN(min) && !isNaN(min))
    return Math.floor(Math.random() * (max - min + 1) + min)

  else
    return false
}

export function ts() {
  return Math.floor(Date.now() / 1000)
}

// =============================================================================
// Debugging
// =============================================================================

export function LE(functionName, error) {
  if (functionName)
    this.l('f', `${functionName}()`)

  if (error) {
    if (error instanceof Error && isObject(error)) {
      if (KIOE(error, 'name'))
        this.l('e', `Name - ${error.name}`)

      if (KIOE(error, 'description'))
        this.l('e', `Description - ${error.description}`)

      if (KIOE(error, 'fileName'))
        this.l('e', `FileName - ${error.fileName}`)

      if (KIOE(error, 'lineNumber'))
        this.l('e', `lineNumber - ${error.lineNumber}`)

      if (KIOE(error, 'columnNumber'))
        this.l('e', `columnNumber - ${error.columnNumber}`)

      if (KIOE(error, 'stack'))
        this.l('e', `stack - ${error.stack}`)

      if (KIOE(error, 'response')) {
        if (KIOE(error.response, 'data'))
          this.l('r', error.response.data, 'Error Response Data')

        else
          this.l('r', error.response, 'Error Response')
      }
      else {
        // The full Error object
        // this.l('r-error', error)
      }
    }
    else if (typeof error == 'string') {
      this.l('e', `Error ${functionName} - Error - String - ${error}`)
    }
  }
  else {
    this.l('e', `Function ${functionName} - Error - no error Object - ${error}`)
  }
}

// Socket Listener Exists
export function SLE(socket, listener = false, append = false) {
  if (!socket)
    throw new Error('SLE expects valid socket')

  if (!listener)
    throw new Error('SLE expects valid listener')

  if (socket._callbacks) {
    if (
      KIO(socket._callbacks, [`$${listener}`])
      && socket._callbacks[`$${listener}`] !== undefined
    ) {
      // Override forces the calling function to re-init the listener
      if (append) {
        // Add another socket instantiation
        return true
      }
      else {
        socket.removeListener(listener)
        return true
      }
    }
    else {
      return true
    }
  }
  else {
    return true
  }
}

export function sortByObjKey(array: Array<any>, key: string) {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}

export function baseSiteURL() {
  if (window) {
    return (
      `${window.location.protocol
      }//${
      window.location.hostname
      }${window.location.port ? `:${window.location.port}` : ''}`
    )
  }
  else {
    throw new Error('baseSiteURL | cannot be run in server context')
  }
}

export function strFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map(word => word[0])
    .join('')

  return firstLetters
}

export function hasSpaces(str: string) {
  if (str)
    return str.includes(' ')

  else
    return str
}

export function getInputSelectedText(input: HTMLInputElement) {
  if (!window)
    return

  let txt
  if (window.getSelection)
    txt = window.getSelection()

  else if (window.document.getSelection)
    txt = window.document.getSelection()

  else if (window.document.selection)
    txt = window.document.selection.createRange().text

  return txt
}

export function isColumnID(id: ColumnID) {
  return typeof id === 'number' && id > 0 && [2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14].includes(id)
}

export function clearLocalStorageWithKeyContaining(key: string) {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const storageKey = localStorage.key(i)
    if (storageKey.includes(key))
      localStorage.removeItem(storageKey)
  }
}
