import type { ApiClientInstance } from '~/api/apiclient'

function SessionID(): number {
  if (typeof window !== 'undefined') {
    const cookies: { [key: string]: string } = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split('=')
      return { ...acc, [key]: decodeURIComponent(value) }
    }, {})

    return 'session_id' in cookies && cookies.session_id ? Number.parseInt(cookies.session_id) : 0
  }
  else {
    return 0
  }
}

export class SessionService {
  constructor(private $api: ApiClientInstance) {}

  initSession(sessionID: number = SessionID()) {
    return this.$api.get(`/s/${sessionID}/init`)
  }

  createSession(sessionData: {
    type: SessionType
    focus: SessionFocus
    individual_name?: string
    individual_relation?: string
  }) {
    return this.$api.post('/sessions', sessionData)
  }

  updateSessionState(columnState: {
    state?: SessionState
    active_trait?: TraitID
    active_column?: ColumnID
  }) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.patch(`/s/${sessionID}/state`, { ...columnState })
  }

  // Traits
  addTrait(trait: Trait) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/traits`, trait)
  }

  updateTrait(trait: {
    tai_id: TraitID
    label?: string
    tai_index?: number
    complete?: boolean
  }) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.patch(`/s/${sessionID}/traits`, trait)
  }

  deleteTrait(traitData: { tai_id: TraitID }) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/traits/delete`, traitData)
  }

  // States
  updateRecentSessionID(sessionID: string) {
    return this.$api.post(`/s/${sessionID}/recent`)
  }

  startSession() {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/start`)
  }

  completeSession() {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/complete`)
  }

  deleteSession(sessionID: SessionID) {
    return this.$api.delete(`/sessions/${sessionID}`)
  }

  initColumn(traitColumnID: number) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.get(`/s/${sessionID}/column/${traitColumnID}/init`)
  }

  addEntry(traitColumnID: number, entry: string) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/column/${traitColumnID}/entry`, { entry })
  }

  syncTime(traitColumnID: number, time: number) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/column/${traitColumnID}/time`, { time })
  }

  updateColumnState(traitColumnID: number, state: WorkingColumnState) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/column/${traitColumnID}/state`, { state })
  }

  updateColumnStateTaiIDColumn(tai_id: TraitID, column: ColumnID, state: WorkingColumnState) {
    const sessionID = SessionID()
    if (!sessionID)
      return Promise.reject(new Error('No session ID found - Close Session'))

    return this.$api.post(`/s/${sessionID}/column/state/${tai_id}/${column}`, { state })
  }
}
