import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import axios from 'axios'

import { API_BASE_URL } from '@/env'

import { KIOE } from '@/utility/utility'

interface ApiClientOptions {
  baseURL?: string
  withCredentials?: boolean
  rejectUnauthorized?: boolean
}

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export class ApiClient {
  private axiosInstance: AxiosInstance
  private authToken: string | null

  constructor(options: ApiClientOptions) {
    this.axiosInstance = axios.create({
      baseURL: options.baseURL ?? `${API_BASE_URL}/api`,
      withCredentials: options.withCredentials || false,
      // httpsAgent: new https.Agent({
      //   rejectUnauthorized: options.rejectUnauthorized ?? false,
      // }),
    })
    this.authToken = null
  }

  setAuthToken(token: string | null): void {
    this.authToken = token
  }

  getAuthToken(): string | null {
    return this.authToken
  }

  setBaseURL(baseURL: string): void {
    this.axiosInstance.defaults.baseURL = baseURL
  }

  getBaseURL(): string {
    return this.axiosInstance.defaults.baseURL || ''
  }

  private getBearerTokenFromCookie(): string | null {
    // Use native browser APIs to access and parse cookies
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split('=')
      return { ...acc, [key]: decodeURIComponent(value) }
    }, {})

    return cookies.token || null // Get the "session" cookie if it exists, otherwise return null
  }

  private async sendRequest<T = any>(method: Method, url: string, config?: AxiosRequestConfig): Promise<T> {
    const requestConfig: AxiosRequestConfig = {
      ...config,
      method,
      url,
    }

    // Check if there is a "token" cookie in the user's browser
    const tokenFromCookie = this.getBearerTokenFromCookie()
    if (tokenFromCookie) {
      requestConfig.headers = {
        ...requestConfig.headers,
        Authorization: `Bearer ${tokenFromCookie}`,
      }
    }

    const response: AxiosResponse = await this.axiosInstance.request(requestConfig)

    // console.log('AxiosResponse response', response)
    // const responseData = validateApiResponse<T>(response)

    if (KIOE(response, 'data'))
      return response.data

    else
      return response
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>('get', url, config)
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>('post', url, { ...config, data })
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>('put', url, { ...config, data })
  }

  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>('patch', url, { ...config, data })
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>('delete', url, config)
  }
}

const api = new ApiClient({})
export type ApiClientInstance = typeof api

export default api
