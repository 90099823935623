import revive_payload_client_h9cbGViDgZ from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pPu07NnKsI from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qyzQJaFzAZ from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_22izsCIkIq from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_036X6EqOGy from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fE0oUaYJsR from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_giG7mcuXnq from "/home/mpire/dmo-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@2.79.1_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/mpire/dmo-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ymPuZy87kc from "/home/mpire/dmo-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@2.79.1_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/mpire/dmo-frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Z435nvgNbx from "/home/mpire/dmo-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@2.79.1_vite@5.4.6_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_E2yfWMaLnK from "/home/mpire/dmo-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/home/mpire/dmo-frontend/.nuxt/unocss.mjs";
import api_GFfDXud5Cr from "/home/mpire/dmo-frontend/plugins/api.ts";
import draggable_hMJ3P6uXQ1 from "/home/mpire/dmo-frontend/plugins/draggable.ts";
import mitt_S0QU5gJPTl from "/home/mpire/dmo-frontend/plugins/mitt.ts";
import sentry_client_shVUlIjFLk from "/home/mpire/dmo-frontend/plugins/sentry.client.ts";
import tippy_client_WKoqvTKVZ4 from "/home/mpire/dmo-frontend/plugins/tippy.client.ts";
import toastify_ScRgZgP9op from "/home/mpire/dmo-frontend/plugins/toastify.ts";
export default [
  revive_payload_client_h9cbGViDgZ,
  unhead_pPu07NnKsI,
  router_qyzQJaFzAZ,
  navigation_repaint_client_22izsCIkIq,
  check_outdated_build_client_036X6EqOGy,
  chunk_reload_client_fE0oUaYJsR,
  plugin_vue3_giG7mcuXnq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ymPuZy87kc,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Z435nvgNbx,
  plugin_client_E2yfWMaLnK,
  unocss_MzCDxu9LMj,
  api_GFfDXud5Cr,
  draggable_hMJ3P6uXQ1,
  mitt_S0QU5gJPTl,
  sentry_client_shVUlIjFLk,
  tippy_client_WKoqvTKVZ4,
  toastify_ScRgZgP9op
]