import { default as createRcycNPeBJlMeta } from "/home/mpire/dmo-frontend/pages/create.vue?macro=true";
import { default as indexJp02RMx15fMeta } from "/home/mpire/dmo-frontend/pages/index.vue?macro=true";
import { default as record42M69R5JvxMeta } from "/home/mpire/dmo-frontend/pages/record.vue?macro=true";
import { default as indexcUV85YMz3cMeta } from "/home/mpire/dmo-frontend/pages/session/[columnID]/index.vue?macro=true";
import { default as _91columnID_93810V65J2HLMeta } from "/home/mpire/dmo-frontend/pages/session/[columnID].vue?macro=true";
import { default as completehNRyQLp6tRMeta } from "/home/mpire/dmo-frontend/pages/session/complete.vue?macro=true";
import { default as overviewshjk9lSSMaMeta } from "/home/mpire/dmo-frontend/pages/session/overview.vue?macro=true";
import { default as sessionVP7XnEUllLMeta } from "/home/mpire/dmo-frontend/pages/session/session.vue?macro=true";
import { default as taisIr2j54FkjqMeta } from "/home/mpire/dmo-frontend/pages/session/tais.vue?macro=true";
import { default as valuesvQflmGgVwKMeta } from "/home/mpire/dmo-frontend/pages/session/values.vue?macro=true";
import { default as sessionsGv1eTGAlp5Meta } from "/home/mpire/dmo-frontend/pages/sessions.vue?macro=true";
import { default as testGdDn3U5t30Meta } from "/home/mpire/dmo-frontend/pages/test.vue?macro=true";
import { default as valuesOTUqqlZnjtMeta } from "/home/mpire/dmo-frontend/pages/values.vue?macro=true";
export default [
  {
    name: "create",
    path: "/create",
    meta: createRcycNPeBJlMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/create.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexJp02RMx15fMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/index.vue")
  },
  {
    name: "record",
    path: "/record",
    meta: record42M69R5JvxMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/record.vue")
  },
  {
    name: _91columnID_93810V65J2HLMeta?.name,
    path: "/session/:columnID()",
    meta: _91columnID_93810V65J2HLMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/session/[columnID].vue"),
    children: [
  {
    name: "session-columnID",
    path: "",
    meta: indexcUV85YMz3cMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/session/[columnID]/index.vue")
  }
]
  },
  {
    name: "session-complete",
    path: "/session/complete",
    meta: completehNRyQLp6tRMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/session/complete.vue")
  },
  {
    name: "session-overview",
    path: "/session/overview",
    meta: overviewshjk9lSSMaMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/session/overview.vue")
  },
  {
    name: "session-session",
    path: "/session/session",
    component: () => import("/home/mpire/dmo-frontend/pages/session/session.vue")
  },
  {
    name: "session-tais",
    path: "/session/tais",
    meta: taisIr2j54FkjqMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/session/tais.vue")
  },
  {
    name: "session-values",
    path: "/session/values",
    component: () => import("/home/mpire/dmo-frontend/pages/session/values.vue")
  },
  {
    name: "sessions",
    path: "/sessions",
    meta: sessionsGv1eTGAlp5Meta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/sessions.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: testGdDn3U5t30Meta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/test.vue")
  },
  {
    name: "values",
    path: "/values",
    meta: valuesOTUqqlZnjtMeta || {},
    component: () => import("/home/mpire/dmo-frontend/pages/values.vue")
  }
]