<script setup lang="ts">
import { appName } from '~/constants'

// All the initial setup while "Loading" is shown
import { useAppStore } from '@/stores/app'

const $app = useAppStore()

const { init, $env, env, activeDeviceMedia, isSessionEnvName } = useEnvironment()

const route = useRoute()

const { $api, $SE } = useNuxtApp()

useHead({
  title: appName,
})






//
// Global Environment Watchers
// ---------------------------

// When the device changes, update the active device
watch(activeDeviceMedia, (media) => {
  if (media && $env.activeDevice !== media)
    $env.updateActiveDevice(media)
})

// Update the environment when the route changes
watch(route, (toRoute) => {
  if (toRoute) {
    const toEnvironment = String(toRoute.name) as EnvironmentSlug
    $env.updateActiveEnvironment(toEnvironment)
  }
})

// When the environment changes, process the layout
watch(env, (toEnvironment, fromEnvironment) => {
  const sessionID = useCookie('session_id')
  if (toEnvironment)
    $env.processLayout(toEnvironment, fromEnvironment)

  switch (toEnvironment) {
    case 'dashboard':
      $app.sessionState = 'dashboard-ready'
      sessionID.value = null
      break
    case 'create':
      $app.sessionState = 'session-create'
      sessionID.value = null
      break

    default:
      break
  }
})

onBeforeMount(() => {
  init()
})
</script>

<template>
  <!-- <VitePwaManifest /> -->
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
@import "@/assets/scss/global.scss";

html, body , #__nuxt{
  height: 100vh;
  margin: 0;
  padding: 0;

  font-family: 'Poppins', sans-serif;

  font-size: 15px;
  line-height:1.5;

  font-weight: 400;

  @apply antialiased;

  .ms-0 {
    font-size: 1rem;
  }
  .ms-1 {
    font-size: 1.200rem;
  }
  .ms-2 {
    font-size: 1.44rem;
  }
  .ms-3 {
    font-size: 1.728rem;
  }
  .ms-4 {
    font-size: 2.074rem;
  }
  .ms-5 {
    font-size: 2.488rem;
  }
  .ms-6 {
    font-size: 2.986rem;
  }
  .ms-7 {
    font-size: 3.583rem;
  }

  h1, h2, h3, h4, h5 {
    margin: 3rem 0 1.38rem;
    font-weight: 500;
  }

  .small, small, .text_small {font-size: 0.833rem;}

}

html.dark {
  background: #222;
  color: white;
}
</style>
