// services/AuthService.ts
import type { ApiClientInstance } from '~/api/apiclient'

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export class AuthService {
  constructor(private $api: ApiClientInstance) {}

  refresh() {
    console.log('Auth service refresh')
    return this.$api.post('/refresh')
  }

  csrf() {
    return this.$api.get('/sanctum/csrf-cookie')
  }

  ping() {
    return this.$api.post('/ping')
  }

  register(credentials: any) {
    return this.$api.post('/register', credentials)
  }

  async login(credentials: any) {
    return this.$api.post('/login', credentials)
  }

  logout() {
    return this.$api.post('/logout')
  }

  user() {
    return this.$api.get('/user')
  }
}
