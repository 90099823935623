import * as Sentry from '@sentry/vue'

import { SENTRY_APP_ID, SENTRY_PROJECT_ID } from '../env'

import {
  API_BASE_URL,
} from '@/env.ts'

interface MPDError extends Error {
  cause?: string
  sentry?: boolean
  info?: string
  extra?: string
}

interface optionsExtraObject {
  [key: string]: any
}

interface SentryOptions {
  tags?: {}
  extra?: optionsExtraObject
  user?: {}
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  Sentry.setContext('dmo_env', {
    API_URL: API_BASE_URL,
  })

  // const router = nuxtApp.router

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: `https://${SENTRY_APP_ID}.ingest.sentry.io/${SENTRY_PROJECT_ID}`,

    integrations: [
      // new Sentry.BrowserTracing({
      // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // }),
      // new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
  })

  const flattenObject = (obj: any, prefix = '') => {
    const flattenedObject: { [key: string]: string } = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key]
        const prefixedKey = prefix ? `${prefix}.${key}` : key
        if (typeof value === 'object' && value !== null)
          Object.assign(flattenedObject, flattenObject(value, prefixedKey))

        else
          flattenedObject[prefixedKey] = JSON.stringify(value)
      }
    }
    return flattenedObject
  }

  const SE = (error: MPDError, options?: SentryOptions | string) => {
    if (error instanceof Error) {
      const response = error.response
      if (response && response.data && typeof response.data === 'object') {
        const stringifiedData = JSON.stringify(flattenObject(response.data))
        if (typeof options === 'string') {
          Sentry.captureException(error, {
            extra: {
              message: options,
              data: stringifiedData,
            },
          })
        }
        else {
          // Todo
          // This is not being used in this application
          if (!('sentry' in error && error.sentry === false)) {
            if ('info' in error) {
              if ('extra' in options && options.extra && typeof options.extra === 'object') {
                options.extra.info = error.info
              }
              else {
                options.extra = {
                  info: error.info,
                }
              }
            }

            if ('extra' in error && typeof error.extra === 'string' && error.extra) {
              const extra = JSON.parse(error.extra)
              options.extra = {
                ...extra,
                ...options.extra,
              }
            }

            options.extra = {
              ...options.extra,
              data: stringifiedData,
            }

            Sentry.captureException(error, options)
          }
        }
      }
      else {
        if (typeof options === 'string') {
          Sentry.captureException(error, {
            extra: {
              message: options,
            },
          })
        }
        else {
          Sentry.captureException(error, options)
        }
      }
    }
    else {
      Sentry.captureException(new Error('Sentry Bad Error Object'), {
        extra: {
          message: 'Sentry tried to capture a non-object error',
          error,
          options,
        },
      })
    }
  }

  const ST: Sentry.Transaction = (name: string, op: string) => {
    const transaction = Sentry.startTransaction({ op, name })
    return transaction
  }

  // End a Transaction - 'endTransaction'
  const ET = (transaction: Sentry.Transaction) => {
    transaction.finish()
  }

  return {

    provide: {
      S: Sentry,
      SE,
      ST,
      ET,
    },
  }
})
