// export const API_BASE_URL = 'https://dmo.test'

// const frontend = 'https://cccaf04e20cb.ngrok.app'
// const backend = 'https//dmo.test'

export const API_BASE_URL = 'https://api.method.drdemartini.com'

export const SENTRY_APP_ID = '2d6cc84d7e374dd983ca2bf6b2252f68@o4505285403344896'
export const SENTRY_PROJECT_ID = '4505285404721152'
export const SENTRY_AUTH_TOKEN = '3ca28b4543ee245d3e2d097ce6e329e81af269bc805cf8213edfd36e3be206d0'

// DB Connection
export const DB_HOST = 'localhost'
export const DB_PORT = '3306'
export const DB_NAME = 'dmo_v2_dev'
export const DB_USER = 'mpire'
export const DB_PASS = 'mpd$Cz7r4p8'

// DEBUG

export const ENV = 'production'
export const DEBUG_API = true
export const DEBUG = false
export const REDIRECT_ON_LOGOUT = true
// Ngrok
export const REDIRECT_LOGOUT_URL = 'https://api.method.drdemartini.com/logout' // https://drdemartini.com/method
// Local
// export const REDIRECT_LOGOUT_URL = 'http://localhost:3070'
