// services/AuthService.ts
import type { ApiClientInstance } from '~/api/apiclient'

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export class UserService {
  constructor(private $api: ApiClientInstance) {}

  async initUserSessions(userID: number) {
    return this.$api.post(`/users/${userID}/sessions`)
  }

  async initUserRecord(userID: number) {
    return this.$api.get(`/users/${userID}/record`)
  }

  async initUserValues(userID: number) {
    return this.$api.get(`/users/${userID}/values`)
  }

  async initUserState(userID: number) {
    return this.$api.get(`/users/${userID}/state`)
  }

  async updateUserState(userID: number, stateData: {
    state_key: string
    state_value: any
  }) {
    return this.$api.patch(`/users/${userID}/state`, stateData)
  }
}
