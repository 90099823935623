const { width: ww, height: wh } = useWindowSize()

export function useWindow() {
  const isMobile = computed(() => ww.value <= 550)
  const isTabletOrMobile = computed(() => ww.value < 1200)
  const isTablet = computed(() => ww.value > 550 && ww.value < 1200)
  const isDesktop = computed(() => ww.value >= 1200)

  const activeDeviceMedia = computed(() => isTabletOrMobile.value ? 'mobile' : 'desktop')

  return {
    activeDeviceMedia,
    ww,
    wh,
    isMobile,
    isTabletOrMobile,
    isTablet,
    isDesktop,
  }
}
