import mitt from 'mitt'

interface ApplicationEvents {
  'session:delete': {
    sessionID: SessionID
  }
}

export default defineNuxtPlugin(() => {
  const emitter = mitt<ApplicationEvents>()

  return {
    provide: {
      e: emitter,
    },
  }
})
