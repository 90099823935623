import { useEnvironmentStore } from '@/stores/environment'

export default function useEnvironment() {
  const $env = useEnvironmentStore()
  const { activeDeviceMedia, ww } = useWindow()

  const route = useRoute()

  const routeName = computed(() => route.name)

  const initialised = ref(false)

  const layout = computed(() => $env.getActiveLayout)

  const activeDevice = computed(() => $env.activeDevice)
  const env = computed(() => $env.environment)

  const isSessionEnv = computed(() =>
    ['session-complete-consideration', 'session-complete-consideration-reactivate', 'session-complete-consideration-create', 'session-entering-tais', 'session-ready-to-start', 'session-in-progress'].includes(String(env.value)))

  const isSessionEnvName = computed(() =>
    ['session-tais',
      'session-overview',
      'session-values',
      'session-columnID'].includes(route.name))

  function init() {
    // Initialise the device layout
    if (!activeDeviceMedia.value)
      throw new Error('Environment | init | activeDevice is not set')

    $env.updateActiveDevice(activeDeviceMedia.value)

    // Initialise the environment from the route name
    if (route && route.name)
      $env.updateActiveEnvironment(route.name)

    else throw new Error('Environment | init | name is not set')

    // Initialise environment
  }

  // Watch for changes in the route name and update the environment accordingly
  watch(routeName, (name) => {
    if (name)
      $env.updateActiveEnvironment(name)
  }, {
    immediate: true,
  })

  // Watch for changes in the active device and update the layout accordingly

  watchEffect(() => {
    initialised.value = !!(env.value && activeDevice.value)
  })

  // Criteria for ensuring the layout environment is available to components
  // Use watch effect to ensure all essential componetns are active

  return {
    activeDevice, // used but subsequent calculations
    activeDeviceMedia, // The direct value, more reliable for template layouts
    $env,
    env,
    init,
    layout,
    initialised,
    isSessionEnv,
    isSessionEnvName,
    ww,
  }
}
