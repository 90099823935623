// =============================================================================
// Environment Store
//
// Is responsible for managing environment state and the layouts with respect to desktop and mobile component states
// =============================================================================

import { defineStore } from 'pinia'
import type { RouteRecordName } from 'vue-router'

type DeviceType = 'mobile' | 'tablet' | 'desktop'

interface EnvironmentRootState {
  mode: number
  activeDevice: ActiveDevice | false
  environment: EnvironmentSlug | false
  environments: Record<string, EnvironmentSlug>
  layout: {
    mobile: {
      header: boolean
      footer: boolean
    }
    tablet: {
      header: boolean
      navigation: string
      component: string
    }
    desktop: {
      header: string
      navigation: string
      createFlyout: boolean
      actionbar: boolean
      flyout: boolean
      footer: boolean
    }
  }
  module: string
  flyout: boolean
}

export const useEnvironmentStore = defineStore({
  id: 'layoutstore',

  state: (): EnvironmentRootState => ({
    mode: 1,
    activeDevice: false,
    environment: false,
    environments: {
      'welcome': 'dashboard',
      'index': 'dashboard',
      'sessions': 'dashboard',
      'values': 'dashboard',
      'record': 'dashboard',
      'create': 'create',
      'session-tais': 'session',
      'session-overview': 'session',
      'session-values': 'session',
      'session-columnID': 'working',
      'session-complete': 'complete',
      'drizzle': 'drizzle',
      'test': 'test',
    },

    layout: {
      mobile: {
        header: true,
        footer: true,
      },
      tablet: {
        header: true,
        navigation: 'mini',
        component: 'modules',
      },
      desktop: {
        header: 'dashboard',
        navigation: 'mini',
        createFlyout: false,
        actionbar: false,
        flyout: false,
        footer: true,
      },
    },
    module: 'company',
    flyout: false,
  }),

  actions: {
    updateActiveDevice(media: ActiveDevice) {
      this.activeDevice = media
    },
    updateActiveEnvironment(routeName: RouteRecordName) {
      if (!(routeName in this.environments))
        throw new Error('Environment Store: updateActiveEnvironment() - routeName is not available in defined environments')

      this.environment = this.environments[String(routeName)]
    },

    updateLayout(elementType: string, value: boolean | string) {
      if (this.activeDevice)
        this.layout[this.activeDevice][elementType] = value
      else
        throw new Error('Environment Store: updateLayout() - activeDevice is not set')
    },

    processLayout(toEnvironment: EnvironmentSlug, fromEnvironment?: EnvironmentSlug | false) {
      switch (toEnvironment) {
        case 'dashboard':
          // Dashboard
          switch (this.activeDevice) {
            case 'desktop':

              if (fromEnvironment === 'create') {
                this.updateLayout('createFlyout', false)
                setTimeout(() => {
                  this.updateLayout('header', 'dashboard')
                  this.updateLayout('navigation', true)
                  this.updateLayout('footer', true)
                }, 300)
              }
              else {
                this.updateLayout('header', 'dashboard')
                this.updateLayout('navigation', true)
                this.updateLayout('createFlyout', false)
                this.updateLayout('footer', true)
              }

              break // Make sure this is here

            case 'mobile':
              this.updateLayout('header', true)
              this.updateLayout('footer', true)
              break

            default:
              break
          }

          // Tablet
          //
          break // Make sure this is here

        case 'create':
          // Dashboard
          switch (this.activeDevice) {
            case 'desktop':
              this.updateLayout('header', 'create')
              this.updateLayout('navigation', false)
              this.updateLayout('createFlyout', false)
              this.updateLayout('footer', true)
              break

            case 'mobile':
              this.updateLayout('header', true)
              this.updateLayout('footer', false)
              break

            default:
              break
          }

          // Tablet
          //
          break // Make sure this is here

        case 'session':
          switch (this.activeDevice) {
            case 'desktop':
              this.updateLayout('header', 'session')
              this.updateLayout('navigation', false)
              this.updateLayout('createFlyout', false)
              this.updateLayout('footer', true)
              break

            case 'mobile':
              this.updateLayout('header', true)
              this.updateLayout('footer', true)
              break

            default:
              break
          }
          break // Make sure this is here

        case 'complete':
          switch (this.activeDevice) {
            case 'desktop':
              this.updateLayout('header', 'complete')
              this.updateLayout('navigation', false)
              this.updateLayout('createFlyout', false)
              this.updateLayout('footer', false)
              break

            case 'mobile':
              this.updateLayout('header', true)
              this.updateLayout('footer', false)
              break

            default:
              break
          }
          break // Make sure this is here
      }
    },

  },

  getters: {
    getActiveLayout: (state) => {
      if (state.activeDevice)
        return state.layout[state.activeDevice]
      else
        return state.layout.desktop
    },
  },
})
